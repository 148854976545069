html,
body {
  scroll-behavior: smooth !important;
}
body {
  height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.App {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.bg-banner {
  background-image: url("./assets/images/homepage/banner-homepage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.bg-service-banner-color {
  background-image: linear-gradient(
    98.05deg,
    rgba(0, 0, 0, 0.784) 36.08%,
    rgba(35, 96, 167, 0.76) 101.32%
  );
}
.bg-education {
  background-image: url("./assets/images/service-page/background-education-service-page.png");
  background-repeat: no-repeat;
  background-size: 100% 60%;
  background-position: top;
}

@media screen and (max-width: 767px) {
  .bg-education {
    background-image: url("./assets/images/service-page/background-education-service-page.png");
    background-repeat: no-repeat;
    background-size: 100% 30%;
    background-position: top;
  }
  .bg-banner {
    background-image: url("./assets/images/homepage/banner-homepage-mobile.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .bg-service-banner {
    background-image: url("./assets/images/service-page/banner-service-page-mobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-blogs {
    background-image: url("./assets/images/blogs-page/background-blogs-page-mobile.png");
    background-repeat: no-repeat;
    background-size: 100% 80%;
    background-position: top;
  }
}
@media screen and (min-width: 768px) {
  .bg-client {
    background-image: url("./assets/images/homepage/background-homepage.png");
    background-position: 0 22%;
    background-repeat: no-repeat;
    background-size: 100% 200%;
    background-position: top;
  }
  .bg-service-banner {
    background-image: url("./assets/images/service-page/banner-service-page.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-corporate-service {
    background-image: url("./assets/images/service-page/background-corporate-service-page.png");
    background-repeat: no-repeat;
    background-size: 100% 85%;
    background-position: bottom;
  }
  .bg-blogs {
    background-image: url("./assets/images/blogs-page/background-blogs-page.png");
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .bg-contact {
    background-image: url("./assets/images/contact-us-page/background-contact-us.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-contact-page {
    background-image: url("./assets/images/contact-us-page/contact-cover.png");
    background-repeat: no-repeat;
    background-size: 50%;
  }
}
/* swiper */
.swiper {
  position: relative;
  padding: 2rem 0;
  padding-bottom: 4rem;
  background: transparent;
  border-radius: 24px;
  width: 100%;

  /* height: fit-content; */
}

.swiper-slide {
  display: flex;
  justify-content: center;
}
